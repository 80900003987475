/* .App {
  text-align: center;
  height: auto;
} */

/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.App-logo {
  height: 10vmin;
  /* pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  /* background-color: 'white'; */
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .nav--container {
  display: flex;
  
  justify-content: space-between;
  align-items: center;
} */

.nav--buttons {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

h3 {
  margin: 0;
  font-family: 'Prompt';
  font-weight: 500;
  
}

p {
  margin-bottom: 5;
  font-family: 'Prompt';
  font-weight: 200;
}

.Main--banner {
  height: 300px;
  max-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 50px;
}

.Main--screen-shot {
  height: 50vmin;
  border-radius: 10px;
}

.Main--screen-shot-container {
  display: flex;
  flex-direction: row;
  padding: 5vmin;
  align-items: center;
  justify-content: space-around;
}

.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.white-svg {
  filter: invert(100%) sepia(11%) saturate(0%) hue-rotate(0deg) brightness(111%) contrast(100%);
}

/* .slideshow {
  margin: 0 auto;
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
}

.slide {
  display: flex;
  height: 400px;
  width: 100vw;
  border-radius: 40px;
} */
