.main {
    
    display: flex;
    justify-content: center;
}



.confirmBox {
    background-color: black;
}